import PropTypes from 'rocket/Libraries/prop-types';

import Box from 'src/components/bits/Box';
import Tooltip from 'src/components/bits/Tooltip';
import SvgIcon from 'src/components/bits/SvgIcon';
import ButtonBase from 'src/components/bits/Button/ButtonBase';

import { SettingsIcon } from 'src/icons';

function SettingsButton(props) {
  const { onClick } = props;
  return (
    <Tooltip title="App Settings">
      <Box
        sx={{
          backgroundColor: 'background.paper',
          borderRadius: '50%',
          bottom: -12,
          boxShadow: 16,
          margin: (theme) => theme.spacing(4),
          position: 'fixed',
          right: 140,
          zIndex: (theme) => theme.zIndex.speedDial,
        }}
        onClick={() => onClick()}
      >
        <ButtonBase
          sx={{
            backgroundColor: 'primary.main',
            borderRadius: '50%',
            color: 'primary.contrastText',
            p: '10px',
          }}
        >
          <SvgIcon>
            <SettingsIcon />
          </SvgIcon>
        </ButtonBase>
      </Box>
    </Tooltip>
  );
}

SettingsButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default SettingsButton;
