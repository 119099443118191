import { useCallback } from 'react';
import toast from 'react-hot-toast';
import PropTypes from 'rocket/Libraries/prop-types';

import Box from 'src/components/bits/Box';
import Button from 'src/components/bits/Button';
import Divider from 'src/components/bits/Divider';
import ListItemButton from 'src/components/bits/List/ListItemButton';
import ListItemIcon from 'src/components/bits/List/ListItemIcon';
import ListItemText from 'src/components/bits/List/ListItemText';
import Popover from 'src/components/bits/Popover';
import RouterLink from 'src/components/bits/RouterLink';
import SvgIcon from 'src/components/bits/SvgIcon';
import Typography from 'src/components/bits/Typography';

import { useRouter } from 'rocket/Hooks/use-router';
import useAuth from 'src/hooks/use-auth';

import { paths } from 'src/paths';
import { logger } from 'src/utilsV2/logger';

import { SettingsIcon, ProfileIcon } from 'src/icons';
import useApplication from 'src/hooks/useApplication';

export function AccountPopover(props) {
  const { anchorEl, onClose, open, ...other } = props;

  const router = useRouter();
  const auth = useAuth();
  const { handleSetSelectedDatabase } = useApplication();

  const { user } = auth;

  const handleLogout = useCallback(async () => {
    try {
      onClose?.();
      await auth.signOut();
      handleSetSelectedDatabase({ _id: null, name: null });
      router.push(paths.index);
    } catch (err) {
      logger(err);
      toast.error('Something went wrong!');
    }
  }, [auth, router, onClose]);

  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        horizontal: 'center',
        vertical: 'bottom',
      }}
      disableScrollLock
      onClose={onClose}
      open={!!open}
      PaperProps={{ sx: { width: 200 } }}
      {...other}
    >
      <Box sx={{ p: 2 }}>
        <Typography variant="body1">{user?.name}</Typography>
        <Typography
          color="text.secondary"
          variant="body2"
        >
          {user?.email}
        </Typography>
      </Box>
      <Divider />
      <Box sx={{ p: 1 }}>
        <ListItemButton
          component={RouterLink}
          href={paths.profile.index}
          onClick={onClose}
          sx={{
            borderRadius: 1,
            px: 1,
            py: 0.5,
          }}
        >
          <ListItemIcon>
            <SvgIcon fontSize="small">
              <ProfileIcon />
            </SvgIcon>
          </ListItemIcon>
          <ListItemText primary={<Typography variant="body1">Profile</Typography>} />
        </ListItemButton>
        <ListItemButton
          component={RouterLink}
          href={paths.profile.settings}
          onClick={onClose}
          sx={{
            borderRadius: 1,
            px: 1,
            py: 0.5,
          }}
        >
          <ListItemIcon>
            <SvgIcon fontSize="small">
              <SettingsIcon />
            </SvgIcon>
          </ListItemIcon>
          <ListItemText primary={<Typography variant="body1">Settings</Typography>} />
        </ListItemButton>
      </Box>
      <Divider sx={{ my: '0 !important' }} />
      <Box
        sx={{
          display: 'flex',
          p: 1,
          justifyContent: 'center',
        }}
      >
        <Button
          color="inherit"
          onClick={handleLogout}
          size="small"
        >
          Logout
        </Button>
      </Box>
    </Popover>
  );
}

AccountPopover.defaultProps = {
  anchorEl: null,
  onClose: null,
  open: false,
};

AccountPopover.propTypes = {
  anchorEl: PropTypes.any,
  onClose: PropTypes.func,
  open: PropTypes.bool,
};
