import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import { Layout as DashboardLayout } from 'src/components/layouts/dashboard';
import { paths } from 'src/paths';

const DataPage = lazy(() => import('src/pages/data'));
const TableDataPage = lazy(() => import('src/pages/data/table-data'));
const AddTableDataPage = lazy(() => import('src/pages/data/add-table-data'));
const EditTableDataPage = lazy(() => import('src/pages/data/edit-table-data'));

export const dataRoutes = [
  {
    path: paths.data.index,
    element: (
      <DashboardLayout>
        <Suspense>
          <Outlet />
        </Suspense>
      </DashboardLayout>
    ),
    children: [
      {
        index: true,
        element: <DataPage />,
      },
    ],
  },
  {
    path: paths.data.table,
    element: (
      <DashboardLayout>
        <Suspense>
          <Outlet />
        </Suspense>
      </DashboardLayout>
    ),
    children: [
      {
        index: true,
        element: <TableDataPage />,
      },
    ],
  },
  {
    path: paths.data.addTable,
    element: (
      <DashboardLayout>
        <Suspense>
          <Outlet />
        </Suspense>
      </DashboardLayout>
    ),
    children: [
      {
        index: true,
        element: <AddTableDataPage />,
      },
    ],
  },
  {
    path: paths.data.editTable,
    element: (
      <DashboardLayout>
        <Suspense>
          <Outlet />
        </Suspense>
      </DashboardLayout>
    ),
    children: [
      {
        index: true,
        element: <EditTableDataPage />,
      },
    ],
  },
];
