import { usePostHog } from 'posthog-js/react';
import { useCallback, useEffect } from 'react';

import PropTypes from 'rocket/Libraries/prop-types';

import Box from 'src/components/bits/Box';
import IconButton from 'src/components/bits/Button/IconButton';
import Stack from 'src/components/bits/Stack';
import SvgIcon from 'src/components/bits/SvgIcon';
import Typography from 'src/components/bits/Typography';

import usePopover from 'rocket/Hooks/use-popover';
import { useDatabase } from 'src/api/useDatabase';
import useApplication from 'src/hooks/useApplication';

import { phKeys } from 'src/config/posthog';
import { ArrowDownIcon } from 'src/icons';

import DatabasePopover from './database-popover';

function DatabaseSwitch(props) {
  const posthog = usePostHog();
  const popover = usePopover();

  const { selectedDatabase, handleSetSelectedDatabase } = useApplication();
  const { databases } = useDatabase();

  const databaseOptions = (databases ?? []).map((database) => ({
    _id: database._id,
    name: database.name,
  }));

  const handleChange = useCallback(
    (db) => {
      handleSetSelectedDatabase(db);
      popover.handleClose();
      posthog.capture(phKeys.database.change);
    },
    [popover, handleSetSelectedDatabase]
  );

  useEffect(() => {
    if ((databases ?? []).length > 0) {
      const isSelectedDatabasesExist = (databases ?? []).find(
        (database) => selectedDatabase._id === database._id
      );

      if (isSelectedDatabasesExist == null) {
        if ((databases ?? []).length > 0) {
          handleChange(databases[0]);
        } else {
          handleChange({
            _id: null,
            name: null,
          });
        }
      }
    }
  }, [databases]);

  return (
    <>
      <Stack
        alignItems="center"
        direction="row"
        spacing={2}
        {...props}
      >
        <Box sx={{ flexGrow: 1 }}>
          <Typography
            color="inherit"
            variant="h6"
          >
            Rocket
          </Typography>
          <Typography
            color="neutral.400"
            variant="body2"
          >
            {selectedDatabase?.name}
          </Typography>
        </Box>
        <IconButton
          onClick={popover.handleOpen}
          ref={popover.anchorRef}
        >
          <SvgIcon sx={{ fontSize: 16 }}>
            <ArrowDownIcon />
          </SvgIcon>
        </IconButton>
      </Stack>
      <DatabasePopover
        anchorEl={popover.anchorRef.current}
        onChange={handleChange}
        onClose={popover.handleClose}
        open={popover.open}
        databases={databaseOptions}
        selectedDatabase={selectedDatabase._id}
      />
    </>
  );
}

DatabaseSwitch.defaultProps = {
  sx: {},
};

DatabaseSwitch.propTypes = {
  sx: PropTypes.object,
};

export default DatabaseSwitch;
