import { lazy } from 'react';

import { authRoutes } from './auth';
import { overviewRoutes } from './overview';
import { databaseRoutes } from './databases';
import { profileRoutes } from './profile';
import { syntheticDataRoutes } from './synthetic-data';
import { tableRoutes } from './table';
import { dataRoutes } from './data';
import { apiRoutes } from './api';
import { webhookRoutes } from './webhook';
import { rocketAuthRoutes } from './rocket-auth';
import { relationshipRoutes } from './relationships';
import { queriesRoutes } from './queries';

const Error404Page = lazy(() => import('src/pages/404'));

export const routes = [
  ...authRoutes,
  ...profileRoutes,
  ...databaseRoutes,
  ...tableRoutes,
  ...syntheticDataRoutes,
  ...overviewRoutes,
  ...dataRoutes,
  ...apiRoutes,
  ...webhookRoutes,
  ...rocketAuthRoutes,
  ...relationshipRoutes,
  ...queriesRoutes,
  {
    path: '*',
    element: <Error404Page />,
  },
];
