import { useEffect, useState } from 'react';

import Breadcrumbs from 'src/components/bits/Breadcrumbs';
import BreadcrumbsSeparator from 'src/components/bits/BreadcrumbsSeparator';
import Chip from 'src/components/bits/Chip';
import RouterLink from 'src/components/bits/RouterLink';

import usePathname from 'rocket/Hooks/use-pathname';
import useApplication from 'src/hooks/useApplication';

import { capitalizeFirstLetter } from 'rocket/Utils/formatter';

function NavBreadCrumbs() {
  const pathname = usePathname();
  const urlPaths = pathname.split('/').splice(1);

  const { breadcrumbs } = useApplication();
  const [processedBreadcrumbs, setProcessedBreadcrumbs] = useState([]);

  useEffect(() => {
    // case 1: Where breadcrumbs is empty
    if (breadcrumbs.length === 0)
      setProcessedBreadcrumbs(urlPaths.map((path) => capitalizeFirstLetter(path)));
    // case 2: Where breadcrumbs is longer than urlPaths
    else if (breadcrumbs.length > urlPaths.length)
      setProcessedBreadcrumbs([...breadcrumbs].splice(0, urlPaths.length));
    // case 3: Everything else
    else setProcessedBreadcrumbs(breadcrumbs);
  }, [breadcrumbs]);

  return (
    <Breadcrumbs separator={<BreadcrumbsSeparator />}>
      {processedBreadcrumbs.map((path, index) => {
        const newPaths = [...urlPaths];
        const url = `/${newPaths.splice(0, index + 1).join('/')}`;

        return (
          <Chip
            component={RouterLink}
            href={url}
            label={path == null ? 'Loading...' : path}
            sx={{ cursor: 'pointer' }}
            key={url}
          />
        );
      })}
    </Breadcrumbs>
  );
}

export default NavBreadCrumbs;
