import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import { Layout as DashboardLayout } from 'src/components/layouts/dashboard';
import { paths } from 'src/paths';

const RelationshipsPage = lazy(() => import('src/pages/relationships'));
const TableRelationshipsPage = lazy(() => import('src/pages/relationships/relationships'));
const EditRelationshipPage = lazy(() => import('src/pages/relationships/edit-relationship'));

export const relationshipRoutes = [
  {
    path: paths.relationships.index,
    element: (
      <DashboardLayout>
        <Suspense>
          <Outlet />
        </Suspense>
      </DashboardLayout>
    ),
    children: [
      {
        index: true,
        element: <RelationshipsPage />,
      },
    ],
  },
  {
    path: paths.relationships.tableRelationships,
    element: (
      <DashboardLayout>
        <Suspense>
          <Outlet />
        </Suspense>
      </DashboardLayout>
    ),
    children: [
      {
        index: true,
        element: <TableRelationshipsPage />,
      },
    ],
  },
  {
    path: paths.relationships.editRelationship,
    element: (
      <DashboardLayout>
        <Suspense>
          <Outlet />
        </Suspense>
      </DashboardLayout>
    ),
    children: [
      {
        index: true,
        element: <EditRelationshipPage />,
      },
    ],
  },
];
