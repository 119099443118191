import { useCallback, useEffect, useState } from 'react';
import PropTypes from 'rocket/Libraries/prop-types';

import useAuth from 'src/hooks/use-auth';
import { useRouter } from 'rocket/Hooks/use-router';
import { paths } from 'src/paths';

function GuestGuard(props) {
  const { children } = props;
  const { isAuthenticated } = useAuth();
  const router = useRouter();
  const [checked, setChecked] = useState(false);

  const check = useCallback(() => {
    if (isAuthenticated) {
      router.replace(paths.overview.index);
    } else {
      setChecked(true);
    }
  }, [isAuthenticated, router]);

  useEffect(
    () => {
      check();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  if (!checked) {
    return null;
  }

  return children;
}

GuestGuard.propTypes = {
  children: PropTypes.node.isRequired,
};

export default GuestGuard;
