import Avatar from 'src/components/bits/Avatar';
import Box from 'src/components/bits/Box';
import ButtonBase from 'src/components/bits/Button/ButtonBase';
import SvgIcon from 'src/components/bits/SvgIcon';

import usePopover from 'rocket/Hooks/use-popover';
import useAuth from 'src/hooks/use-auth';

import { ProfileIcon } from 'src/icons';

import { AccountPopover } from './account-popover';

function AccountButton() {
  const popover = usePopover();
  const auth = useAuth();

  const { user } = auth;

  return (
    <>
      <Box
        component={ButtonBase}
        onClick={popover.handleOpen}
        ref={popover.anchorRef}
        sx={{
          alignItems: 'center',
          display: 'flex',
          borderWidth: 2,
          borderStyle: 'solid',
          borderColor: 'divider',
          height: 40,
          width: 40,
          borderRadius: '50%',
        }}
      >
        <Avatar
          sx={{
            height: 32,
            width: 32,
          }}
          src={`data:image/svg+xml;utf8,${encodeURIComponent(user?.avatar ?? 'Unknown')}`}
        >
          <SvgIcon>
            <ProfileIcon />
          </SvgIcon>
        </Avatar>
      </Box>
      <AccountPopover
        anchorEl={popover.anchorRef.current}
        onClose={popover.handleClose}
        open={popover.open}
      />
    </>
  );
}

export default AccountButton;
