import PropTypes from 'rocket/Libraries/prop-types';

import MenuItem from 'src/components/bits/MenuItem';
import Popover from 'src/components/bits/Popover';

function DatabasePopover(props) {
  const {
    anchorEl,
    onChange,
    onClose,
    open = false,
    databases,
    selectedDatabase,
    ...other
  } = props;

  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        horizontal: 'right',
        vertical: 'bottom',
      }}
      disableScrollLock
      transformOrigin={{
        horizontal: 'right',
        vertical: 'top',
      }}
      keepMounted
      onClose={onClose}
      open={open}
      PaperProps={{ sx: { width: 180 } }}
      {...other}
    >
      {databases.map((database) => (
        <MenuItem
          key={database._id}
          onClick={() => onChange?.(database)}
          selected={selectedDatabase === database._id}
        >
          {database.name}
        </MenuItem>
      ))}

      {databases.length === 0 && <MenuItem selected>No database found</MenuItem>}
    </Popover>
  );
}

DatabasePopover.defaultProps = {
  anchorEl: null,
  onChange: null,
  onClose: false,
  open: false,
  selectedDatabase: null,
};

DatabasePopover.propTypes = {
  anchorEl: PropTypes.any,
  onChange: PropTypes.func,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  databases: PropTypes.array.isRequired,
  selectedDatabase: PropTypes.string,
};

export default DatabasePopover;
