import { LocalizationProvider as LocalizationProviderMUI } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import PropTypes from 'rocket/Libraries/prop-types';

function LocalizationProvider(props) {
  const { children } = props;

  return <LocalizationProviderMUI dateAdapter={AdapterDayjs}>{children}</LocalizationProviderMUI>;
}

LocalizationProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LocalizationProvider;
