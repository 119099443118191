import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import { Layout as DashboardLayout } from 'src/components/layouts/dashboard';
import { paths } from 'src/paths';

const DatabasesPage = lazy(() => import('src/pages/databases'));
const CreateDatabasePage = lazy(() => import('src/pages/databases/new-database'));
const EditDatabasePage = lazy(() => import('src/pages/databases/edit-database'));

export const databaseRoutes = [
  {
    path: paths.database.index,
    element: (
      <DashboardLayout selectedDatabaseNeeded={false}>
        <Suspense>
          <Outlet />
        </Suspense>
      </DashboardLayout>
    ),
    children: [
      {
        index: true,
        element: <DatabasesPage />,
      },
    ],
  },
  {
    path: paths.database.new,
    element: (
      <DashboardLayout selectedDatabaseNeeded={false}>
        <Suspense>
          <Outlet />
        </Suspense>
      </DashboardLayout>
    ),
    children: [
      {
        index: true,
        element: <CreateDatabasePage />,
      },
    ],
  },
  {
    path: paths.database.edit,
    element: (
      <DashboardLayout selectedDatabaseNeeded={false}>
        <Suspense>
          <Outlet />
        </Suspense>
      </DashboardLayout>
    ),
    children: [
      {
        index: true,
        element: <EditDatabasePage />,
      },
    ],
  },
];
