import { createTheme as createMuiTheme, responsiveFontSizes } from '@mui/material/styles';

import { createOptions as createBaseOptions } from './base/create-options';
import { createOptions as createDarkOptions } from './dark/create-options';
import { createOptions as createHyperDarkOptions } from './hyper-dark/create-options';
import { createOptions as createLightOptions } from './light/create-options';

export const createTheme = (config) => {
  let palette = null;

  switch (config.paletteMode) {
    case 'dark':
      palette = createDarkOptions({
        contrast: config.contrast,
      });
      break;
    case 'light':
      palette = createLightOptions({
        contrast: config.contrast,
      });
      break;
    case 'hyper-dark':
      palette = createHyperDarkOptions({
        contrast: config.contrast,
      });
      break;
    default:
      palette = createDarkOptions({
        contrast: config.contrast,
      });
      break;
  }

  let theme = createMuiTheme(
    // Base options available for both dark and light palette modes
    createBaseOptions({
      direction: config.direction,
    }),
    // Options based on selected palette mode, color preset and contrast
    palette
  );

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
};
