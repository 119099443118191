import PropTypes from 'rocket/Libraries/prop-types';

import ContainerMUI from '@mui/material/Container';

function Container(props) {
  const { children, maxWidth } = props;
  return <ContainerMUI maxWidth={maxWidth}>{children}</ContainerMUI>;
}

Container.defaultProps = {
  maxWidth: false,
};

Container.propTypes = {
  children: PropTypes.node.isRequired,
  maxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

export default Container;
