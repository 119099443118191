export const amplifyConfig = {
  aws_user_pools_id: import.meta.env.VITE_AWS_USER_POOLS_ID,
  aws_user_pools_web_client_id: import.meta.env.VITE_AWS_USER_POOLS_WEB_CLIENT_ID,
  aws_cognito_region: import.meta.env.VITE_AWS_COGNITO_REGION,
};

export const version = '6.4.2';

export const apiUri = import.meta.env.VITE_BACKEND_API;

export const isDevelopment = import.meta.env.VITE_NODE_ENV === 'development';

export const sentryDSN = import.meta.env.VITE_SENTRY_DSN;
export const posthogKey = import.meta.env.VITE_POSTHOG_KEY;
