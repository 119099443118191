import { useEffect } from 'react';
import * as Sentry from '@sentry/react';
import Helmet from 'rocket/Libraries/helmet';
import PropTypes from 'rocket/Libraries/prop-types';
import { PostHogProvider, usePostHog } from 'posthog-js/react';
import { useLocation } from 'react-router';

import 'src/global.css';

import CssBaseLine from 'src/components/bits/CssBaseline';
import Error from 'src/components/bits/Error/Error';
import SplashScreen from 'src/components/bits/SplashScreen';
import Toaster from 'src/components/bits/Toaster';

import { routes } from 'src/routes';
import { createTheme } from 'src/theme';

import LocalizationProvider from 'rocket/Provider/LocalizationProvider';
import ThemeProvider from 'rocket/Provider/ThemeProvider';
import { ApplicationProvider } from 'src/contexts/ApplicationProvider';
import { AuthProvider } from 'src/contexts/auth';

import useRoutes from 'rocket/Hooks/react-router-dom';
import useNprogress from 'rocket/Hooks/use-nprogress';
import useAuth from 'src/hooks/use-auth';
import useApplication from 'src/hooks/useApplication';

import { isDevelopment, posthogKey } from './config';

function PostHogProviderWrapper(props) {
  const { children } = props;

  const options = {
    api_host: 'https://app.posthog.com',
  };

  if (isDevelopment) return children;

  return (
    <PostHogProvider
      apiKey={posthogKey}
      options={options}
    >
      {children}
    </PostHogProvider>
  );
}

PostHogProviderWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

function Providers(props) {
  const { children } = props;

  return (
    <LocalizationProvider>
      <AuthProvider>
        <PostHogProviderWrapper>
          <ApplicationProvider>{children}</ApplicationProvider>
        </PostHogProviderWrapper>
      </AuthProvider>
    </LocalizationProvider>
  );
}

Providers.propTypes = {
  children: PropTypes.node.isRequired,
};

function Initializer(props) {
  const { children } = props;

  useNprogress();
  const auth = useAuth();
  const application = useApplication();
  const location = useLocation();
  const posthog = usePostHog();

  const theme = createTheme({
    contrast: application.contrast,
    paletteMode: application.paletteMode,
  });

  const showSlashScreen = !auth.isInitialized;

  useEffect(() => {
    posthog.capture('$pageview');
  }, [location]);

  return (
    <ThemeProvider theme={theme}>
      <Helmet>
        <meta
          name="color-scheme"
          content={application.paletteMode}
        />
        <meta
          name="theme-color"
          content={theme.palette.neutral[900]}
        />
      </Helmet>
      <CssBaseLine />
      <Sentry.ErrorBoundary fallback={<Error title="Something went wrong!" />}>
        {showSlashScreen ? <SplashScreen /> : children}
      </Sentry.ErrorBoundary>
      <Toaster />
    </ThemeProvider>
  );
}

Initializer.propTypes = {
  children: PropTypes.node.isRequired,
};

export function App() {
  const element = useRoutes(routes);

  return (
    <Providers>
      <Initializer>{element}</Initializer>
    </Providers>
  );
}
