import PropTypes from 'rocket/Libraries/prop-types';

import { withAuthGuard } from 'src/hocs/with-auth-guard';

import useApplication from 'src/hooks/useApplication';

import VerticalLayout from './vertical-layout';
import SettingsButton from './settings/settings-button';
import SettingsDrawer from './settings/settings-drawer';

import { useSections } from './config';

export const Layout = withAuthGuard((props) => {
  const sections = useSections();

  const {
    openDrawer,
    contrast,
    paletteMode,
    handleSettingsDrawerOpen,
    handleSettingsDrawerClose,
    handleSettingsReset,
    handleSettingsUpdate,
  } = useApplication();

  return (
    <>
      <VerticalLayout
        sections={sections}
        {...props}
      />

      <SettingsButton onClick={handleSettingsDrawerOpen} />
      <SettingsDrawer
        onClose={handleSettingsDrawerClose}
        onReset={handleSettingsReset}
        onUpdate={handleSettingsUpdate}
        open={openDrawer}
        values={{
          contrast,
          paletteMode,
        }}
      />
    </>
  );
});

Layout.defaultProps = {
  selectedDatabaseNeeded: true,
  children: null,
};

Layout.propTypes = {
  children: PropTypes.node,
  selectedDatabaseNeeded: PropTypes.bool,
};
