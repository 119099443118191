import PropTypes from 'rocket/Libraries/prop-types';

import Box from 'src/components/bits/Box';
import Logo from 'src/components/bits/Logo';
import Stack from 'src/components/bits/Stack';
import Drawer from 'src/components/bits/Drawer';
import Button from 'src/components/bits/Button';
import SvgIcon from 'src/components/bits/SvgIcon';
import Scrollbar from 'src/components/bits/Scrollbar';
import RouterLink from 'src/components/bits/RouterLink';
import Typography from 'src/components/bits/Typography';

import usePathname from 'rocket/Hooks/use-pathname';
import useNavCssVars from 'src/theme/useNavCssVars';

import { paths } from 'src/paths';

import { DocumentationIcon } from 'src/icons';

import DatabaseSwitch from '../database-switch';
import { SideNavSection } from './side-nav-section';

const SIDE_NAV_WIDTH = 280;

export function SideNav(props) {
  const { sections = [] } = props;
  const pathname = usePathname();
  const cssVars = useNavCssVars();

  return (
    <Drawer
      anchor="left"
      open
      PaperProps={{
        sx: {
          ...cssVars,
          backgroundColor: 'var(--nav-bg)',
          borderRightColor: 'var(--nav-border-color)',
          borderRightStyle: 'solid',
          borderRightWidth: 1,
          color: 'var(--nav-color)',
          width: SIDE_NAV_WIDTH,
        },
      }}
      variant="permanent"
    >
      <Scrollbar
        sx={{
          height: '100%',
          '& .simplebar-content': {
            height: '100%',
          },
          '& .simplebar-scrollbar:before': {
            background: 'var(--nav-scrollbar-color)',
          },
        }}
      >
        <Stack sx={{ height: '100%' }}>
          <Stack
            alignItems="center"
            direction="row"
            spacing={2}
            sx={{ p: 3 }}
          >
            <Box
              component={RouterLink}
              href={paths.overview.index}
              sx={{
                borderColor: 'var(--nav-logo-border)',
                borderRadius: 1,
                borderStyle: 'solid',
                borderWidth: 1,
                display: 'flex',
                height: 40,
                p: '4px',
                width: 40,
              }}
            >
              <Logo />
            </Box>
            <DatabaseSwitch sx={{ flexGrow: 1 }} />
          </Stack>
          <Stack
            component="nav"
            spacing={2}
            sx={{
              flexGrow: 1,
              px: 2,
            }}
          >
            {sections.map((section, index) => (
              <SideNavSection
                items={section.items}
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                pathname={pathname}
                subheader={section.subheader}
              />
            ))}
          </Stack>
          <Box sx={{ p: 3 }}>
            <Typography variant="subtitle1">Need help?</Typography>
            <Typography
              color="neutral.400"
              sx={{ mb: 2 }}
              variant="body2"
            >
              Please check our docs.
            </Typography>
            <Button
              component="a"
              fullWidth
              href={paths.docs}
              startIcon={
                <SvgIcon>
                  <DocumentationIcon />
                </SvgIcon>
              }
              target="_blank"
              variant="contained"
            >
              Documentation
            </Button>
          </Box>
        </Stack>
      </Scrollbar>
    </Drawer>
  );
}

SideNav.defaultProps = {
  sections: [],
};

SideNav.propTypes = {
  sections: PropTypes.array,
};
