import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import { Layout as DashboardLayout } from 'src/components/layouts/dashboard';
import { paths } from 'src/paths';

const QueriesPage = lazy(() => import('src/pages/queries'));
const TableQueriesPage = lazy(() => import('src/pages/queries/table-queries'));
const NewTableQueriesPage = lazy(() => import('src/pages/queries/new-table-queries'));
const EditTableQueriesPage = lazy(() => import('src/pages/queries/edit-table-queries'));

export const queriesRoutes = [
  {
    path: paths.queries.index,
    element: (
      <DashboardLayout>
        <Suspense>
          <Outlet />
        </Suspense>
      </DashboardLayout>
    ),
    children: [
      {
        index: true,
        element: <QueriesPage />,
      },
    ],
  },
  {
    path: paths.queries.table,
    element: (
      <DashboardLayout>
        <Suspense>
          <Outlet />
        </Suspense>
      </DashboardLayout>
    ),
    children: [
      {
        index: true,
        element: <TableQueriesPage />,
      },
    ],
  },
  {
    path: paths.queries.addQueries,
    element: (
      <DashboardLayout>
        <Suspense>
          <Outlet />
        </Suspense>
      </DashboardLayout>
    ),
    children: [
      {
        index: true,
        element: <NewTableQueriesPage />,
      },
    ],
  },
  {
    path: paths.queries.editQueries,
    element: (
      <DashboardLayout>
        <Suspense>
          <Outlet />
        </Suspense>
      </DashboardLayout>
    ),
    children: [
      {
        index: true,
        element: <EditTableQueriesPage />,
      },
    ],
  },
];
