import PropTypes from 'rocket/Libraries/prop-types';
import useMediaQuery from 'rocket/Hooks/use-media-query';

import Box from 'src/components/bits/Box';
import Container from 'src/components/bits/Container';
import Typography from 'src/components/bits/Typography';

function ComponentError(props) {
  const { title, description } = props;
  const mdUp = useMediaQuery((theme) => theme.breakpoints.down('md'));

  return (
    <Box
      component="main"
      sx={{
        alignItems: 'center',
        display: 'flex',
        flexGrow: 1,
        py: '80px',
      }}
    >
      <Container maxWidth="lg">
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            mb: 6,
          }}
        >
          <Box
            alt="Not found"
            component="img"
            src="/assets/503.png"
            sx={{
              height: 'auto',
              maxWidth: '100%',
              width: 400,
            }}
          />
        </Box>
        <Typography
          align="center"
          variant={mdUp ? 'h1' : 'h4'}
        >
          {title}
        </Typography>
        <Typography
          align="center"
          color="text.secondary"
          sx={{ mt: 0.5 }}
        >
          {description}
        </Typography>
      </Container>
    </Box>
  );
}

ComponentError.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default ComponentError;
