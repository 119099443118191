export const phKeys = {
  database: {
    change: 'DATABASE_CHANGE',
    delete: 'DATABASE_DELETE',
    create: 'DATABASE_CREATE',
    edit: 'DATABASE_EDIT',
  },
  table: {
    delete: 'TABLE_DELETE',
    create: 'TABLE_CREATE',
    edit: 'TABLE_EDIT',
    createAttribute: 'TABLE_ATTRIBUTE_CREATE',
    editAttribute: 'TABLE_ATTRIBUTE_EDIT',
    deleteAttribute: 'TABLE_ATTRIBUTE_DELETE',
  },
  rocketAuth: {
    create: 'AUTH_USER_CREATE',
    edit: 'AUTH_USER_EDIT',
    delete: 'AUTH_USER_DELETE',
  },
  webhook: {
    edit: 'WEBHOOK_EDIT',
  },
  api: {
    edit: 'API_EDIT',
  },
  syntheticData: {
    edit: 'SYNTHETIC_DATA_EDIT',
    generate: 'SYNTHETIC_DATA_GENERATE',
  },
  data: {
    create: 'DATA_CREATE',
    edit: 'DATA_EDIT',
    delete: 'DATA_DELETE',
  },
  queries: {
    create: 'QUERIES_CREATE',
    edit: 'QUERIES_EDIT',
    delete: 'QUERIES_DELETE',
  },
  relationship: {
    edit: 'RELATIONSHIP_EDIT',
    delete: 'RELATIONSHIP_DELETE',
  },
};
