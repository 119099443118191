import AuthGuard from 'src/guards/auth-guard';

export const withAuthGuard = (Component) =>
  function WithAuthGuard(props) {
    return (
      <AuthGuard>
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <Component {...props} />
      </AuthGuard>
    );
  };
