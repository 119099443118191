import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import { Layout as DashboardLayout } from 'src/components/layouts/dashboard';
import { paths } from 'src/paths';

const WebhookPage = lazy(() => import('src/pages/webhook'));
const EditTableWebhookPage = lazy(() => import('src/pages/webhook/edit-table-webhook'));

export const webhookRoutes = [
  {
    path: paths.webhook.index,
    element: (
      <DashboardLayout>
        <Suspense>
          <Outlet />
        </Suspense>
      </DashboardLayout>
    ),
    children: [
      {
        index: true,
        element: <WebhookPage />,
      },
    ],
  },
  {
    path: paths.webhook.editTable,
    element: (
      <DashboardLayout>
        <Suspense>
          <Outlet />
        </Suspense>
      </DashboardLayout>
    ),
    children: [
      {
        index: true,
        element: <EditTableWebhookPage />,
      },
    ],
  },
];
