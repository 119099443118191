import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import { Layout as DashboardLayout } from 'src/components/layouts/dashboard';
import { paths } from 'src/paths';

const APIsPage = lazy(() => import('src/pages/apis'));
const EditTableAPIPage = lazy(() => import('src/pages/apis/edit-table-api'));

export const apiRoutes = [
  {
    path: paths.apis.index,
    element: (
      <DashboardLayout>
        <Suspense>
          <Outlet />
        </Suspense>
      </DashboardLayout>
    ),
    children: [
      {
        index: true,
        element: <APIsPage />,
      },
    ],
  },
  {
    path: paths.apis.editTable,
    element: (
      <DashboardLayout>
        <Suspense>
          <Outlet />
        </Suspense>
      </DashboardLayout>
    ),
    children: [
      {
        index: true,
        element: <EditTableAPIPage />,
      },
    ],
  },
];
