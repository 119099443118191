import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import { Layout as DashboardLayout } from 'src/components/layouts/dashboard';
import { paths } from 'src/paths';

const TablesPage = lazy(() => import('src/pages/tables'));
const CreateTablesPage = lazy(() => import('src/pages/tables/create'));
const TableDetailsPage = lazy(() => import('src/pages/tables/detail'));
const EditAttributePage = lazy(() => import('src/pages/tables/edit-attribute'));
const NewAttributePage = lazy(() => import('src/pages/tables/new-attribute'));
const EditTablePage = lazy(() => import('src/pages/tables/edit'));

export const tableRoutes = [
  {
    path: paths.table.index,
    element: (
      <DashboardLayout>
        <Suspense>
          <Outlet />
        </Suspense>
      </DashboardLayout>
    ),
    children: [
      {
        index: true,
        element: <TablesPage />,
      },
    ],
  },
  {
    path: paths.table.create,
    element: (
      <DashboardLayout>
        <Suspense>
          <Outlet />
        </Suspense>
      </DashboardLayout>
    ),
    children: [
      {
        index: true,
        element: <CreateTablesPage />,
      },
    ],
  },
  {
    path: paths.table.details,
    element: (
      <DashboardLayout>
        <Suspense>
          <Outlet />
        </Suspense>
      </DashboardLayout>
    ),
    children: [
      {
        index: true,
        element: <TableDetailsPage />,
      },
    ],
  },
  {
    path: paths.table.newAttribute,
    element: (
      <DashboardLayout>
        <Suspense>
          <Outlet />
        </Suspense>
      </DashboardLayout>
    ),
    children: [
      {
        index: true,
        element: <NewAttributePage />,
      },
    ],
  },
  {
    path: paths.table.editAttribute,
    element: (
      <DashboardLayout>
        <Suspense>
          <Outlet />
        </Suspense>
      </DashboardLayout>
    ),
    children: [
      {
        index: true,
        element: <EditAttributePage />,
      },
    ],
  },
  {
    path: paths.table.edit,
    element: (
      <DashboardLayout>
        <Suspense>
          <Outlet />
        </Suspense>
      </DashboardLayout>
    ),
    children: [
      {
        index: true,
        element: <EditTablePage />,
      },
    ],
  },
];
