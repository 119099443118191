import { useCallback } from 'react';
import PropTypes from 'rocket/Libraries/prop-types';

import Drawer from 'src/components/bits/Drawer';
import IconButton from 'src/components/bits/Button/IconButton';
import Stack from 'src/components/bits/Stack';
import SvgIcon from 'src/components/bits/SvgIcon';
import Typography from 'src/components/bits/Typography';
import Scrollbar from 'src/components/bits/Scrollbar';

import { RefreshIcon, XIcon } from 'src/icons';

import OptionsContrast from './options-contrast';
import OptionsColorScheme from './options-color-scheme';

function SettingsDrawer(props) {
  const { onClose, onUpdate, onReset, open, values = {}, ...other } = props;

  const handleFieldUpdate = useCallback(
    (field, value) => {
      onUpdate?.({
        [field]: value,
      });
    },
    [onUpdate]
  );

  return (
    <Drawer
      disableScrollLock
      anchor="right"
      onClose={onClose}
      open={open}
      ModalProps={{
        BackdropProps: {
          invisible: true,
        },
        sx: { zIndex: 1400 },
      }}
      PaperProps={{
        elevation: 24,
        sx: {
          maxWidth: '100%',
          width: 440,
        },
      }}
      {...other}
    >
      <Scrollbar
        sx={{
          height: '100%',
          '& .simplebar-content': {
            height: '100%',
          },
          '& .simplebar-scrollbar:before': {
            background: 'var(--nav-scrollbar-color)',
          },
        }}
      >
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="space-between"
          spacing={3}
          sx={{
            px: 3,
            pt: 2,
          }}
        >
          <Typography variant="h6">App Settings</Typography>
          <Stack
            alignItems="center"
            direction="row"
            spacing={0.5}
          >
            <IconButton
              color="inherit"
              onClick={onReset}
            >
              <SvgIcon fontSize="small">
                <RefreshIcon />
              </SvgIcon>
            </IconButton>{' '}
            <IconButton
              color="inherit"
              onClick={onClose}
            >
              <SvgIcon>
                <XIcon />
              </SvgIcon>
            </IconButton>
          </Stack>
        </Stack>
        <Stack
          spacing={5}
          sx={{ p: 3 }}
        >
          <OptionsColorScheme
            onChange={(value) => handleFieldUpdate('paletteMode', value)}
            value={values.paletteMode}
          />
          <OptionsContrast
            onChange={(value) => handleFieldUpdate('contrast', value)}
            value={values.contrast}
          />
        </Stack>
      </Scrollbar>
    </Drawer>
  );
}

SettingsDrawer.defaultProps = {
  onClose: null,
  onReset: null,
  onUpdate: null,
  open: false,
  values: {},
};

SettingsDrawer.propTypes = {
  onClose: PropTypes.func,
  onReset: PropTypes.func,
  onUpdate: PropTypes.func,
  open: PropTypes.bool,
  values: PropTypes.object,
};

export default SettingsDrawer;
