import PropTypes from 'rocket/Libraries/prop-types';
import { styled } from '@mui/material/styles';

import useApplication from 'src/hooks/useApplication';
import useMediaQuery from 'rocket/Hooks/use-media-query';

import ComponentError from 'src/components/bits/Error/ComponentError';

import { MobileNav } from '../mobile-nav';
import { SideNav } from './side-nav';
import TopNav from './top-nav';

import { useMobileNav } from './use-mobile-nav';

const SIDE_NAV_WIDTH = 280;

const VerticalLayoutRoot = styled('div')(({ theme }) => ({
  display: 'flex',
  flex: '1 1 auto',
  maxWidth: '100%',
  [theme.breakpoints.up('lg')]: {
    paddingLeft: SIDE_NAV_WIDTH,
  },
}));

const VerticalLayoutContainer = styled('div')({
  display: 'flex',
  flex: '1 1 auto',
  flexDirection: 'column',
  width: '100%',
});

function VerticalLayout(props) {
  const { children, sections, selectedDatabaseNeeded } = props;
  const { selectedDatabase } = useApplication();
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'));
  const mobileNav = useMobileNav();

  const returnChildren = () => {
    if (selectedDatabaseNeeded) {
      if (selectedDatabase._id) {
        return children;
      }

      return (
        <ComponentError
          title="Please select a database first"
          description="Please select a database from the sidebar, if there is no database create one from the database page."
        />
      );
    }

    return children;
  };

  return (
    <>
      <TopNav
        onMobileNavOpen={mobileNav.handleOpen}
        showBreadcrumbs={selectedDatabaseNeeded ? !!selectedDatabase._id : true}
      />
      {lgUp && <SideNav sections={sections} />}
      {!lgUp && (
        <MobileNav
          onClose={mobileNav.handleClose}
          open={mobileNav.open}
          sections={sections}
        />
      )}
      <VerticalLayoutRoot>
        <VerticalLayoutContainer>{returnChildren()}</VerticalLayoutContainer>
      </VerticalLayoutRoot>
    </>
  );
}

VerticalLayout.defaultProps = {
  sections: [],
  children: null,
  selectedDatabaseNeeded: true,
};

VerticalLayout.propTypes = {
  children: PropTypes.node,
  sections: PropTypes.array,
  selectedDatabaseNeeded: PropTypes.bool,
};

export default VerticalLayout;
