import React, { useState, useMemo, useCallback } from 'react';

import PropTypes from 'rocket/Libraries/prop-types';

const initialState = {
  selectedDatabase: localStorage.getItem('selectedDatabase')
    ? JSON.parse(localStorage.getItem('selectedDatabase'))
    : {
        _id: null,
        name: null,
      },
  openDrawer: false,
  paletteMode: localStorage.getItem('paletteMode') ?? 'dark',
  contrast: localStorage.getItem('contrast') ?? 'normal',
};

const defaultSettings = {
  contrast: 'normal',
  paletteMode: 'dark',
};

export const ApplicationContext = React.createContext(initialState);
export const ApplicationConsumer = ApplicationContext.Consumer;

export function ApplicationProvider({ children }) {
  const [selectedDatabase, setSelectedDatabase] = useState(initialState.selectedDatabase);
  const [openDrawer, setOpenDrawer] = useState(initialState.openDrawer);
  const [paletteMode, setPalletteMode] = useState(initialState.paletteMode);
  const [contrast, setContrast] = useState(initialState.contrast);
  const [breadcrumbs, setBreadcrumbs] = useState([]);

  const handleSetSelectedDatabase = useCallback((database) => {
    localStorage.setItem('selectedDatabase', JSON.stringify(database));
    setSelectedDatabase(database);
  }, []);

  const handleSettingsDrawerClose = useCallback(() => {
    setOpenDrawer(false);
  }, []);

  const handleSettingsDrawerOpen = useCallback(() => {
    setOpenDrawer(true);
  }, []);

  const handleSettingsReset = useCallback(() => {
    setPalletteMode(defaultSettings.paletteMode);
    setContrast(defaultSettings.contrast);
  }, []);

  const handleSettingsUpdate = useCallback((settings) => {
    if (settings.paletteMode) {
      localStorage.setItem('paletteMode', settings.paletteMode);
      setPalletteMode(settings.paletteMode);
    }
    if (settings.contrast) {
      localStorage.setItem('contrast', settings.contrast);
      setContrast(settings.contrast);
    }
  }, []);

  const handleSetBreadcrumbs = useCallback((crumbs) => {
    setBreadcrumbs(crumbs);
  }, []);

  const state = useMemo(
    () => ({
      selectedDatabase,
      openDrawer,
      contrast,
      paletteMode,
      breadcrumbs,

      handleSetSelectedDatabase,
      handleSettingsDrawerOpen,
      handleSettingsDrawerClose,
      handleSettingsReset,
      handleSettingsUpdate,
      handleSetBreadcrumbs,
    }),
    [
      selectedDatabase,
      openDrawer,
      contrast,
      paletteMode,
      breadcrumbs,

      handleSetSelectedDatabase,
      handleSettingsDrawerOpen,
      handleSettingsDrawerClose,
      handleSettingsReset,
      handleSettingsUpdate,
      handleSetBreadcrumbs,
    ]
  );

  return <ApplicationContext.Provider value={state}>{children}</ApplicationContext.Provider>;
}

ApplicationProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
