import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import { Layout as DashboardLayout } from 'src/components/layouts/dashboard';
import { paths } from 'src/paths';

const OverviewPage = lazy(() => import('src/pages/overview'));

export const overviewRoutes = [
  {
    path: paths.overview.index,
    element: (
      <DashboardLayout>
        <Suspense>
          <Outlet />
        </Suspense>
      </DashboardLayout>
    ),
    children: [
      {
        index: true,
        element: <OverviewPage />,
      },
    ],
  },
];
