import dayjs from 'dayjs';
import numeral from 'numeral';

export function formatDate(date) {
  return dayjs(date).format('YYYY-MM-DD');
}

export function formatDateTime(date) {
  return dayjs(date).format('YYYY-MM-DD HH:MM:ss A');
}

export const formatNumberWithDecimals = (number) => numeral(number).format('0,0.00');
export const formatNumberWithNoDecimals = (number) => numeral(number).format('0,0');
export const formatNumber = (number) =>
  Number.isInteger(number) ? numeral(number).format('0,0') : numeral(number).format('0,0.00');

export const capitalizeFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1);
