import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import { Layout as DashboardLayout } from 'src/components/layouts/dashboard';
import { paths } from 'src/paths';

const RocketAuthPage = lazy(() => import('src/pages/rocket-auth'));
const AddUsersAuthPage = lazy(() => import('src/pages/rocket-auth/add-auth-user'));
const EditUsersAuthPage = lazy(() => import('src/pages/rocket-auth/edit-auth-user'));

export const rocketAuthRoutes = [
  {
    path: paths.rocketAuth.index,
    element: (
      <DashboardLayout>
        <Suspense>
          <Outlet />
        </Suspense>
      </DashboardLayout>
    ),
    children: [
      {
        index: true,
        element: <RocketAuthPage />,
      },
    ],
  },
  {
    path: paths.rocketAuth.newUser,
    element: (
      <DashboardLayout>
        <Suspense>
          <Outlet />
        </Suspense>
      </DashboardLayout>
    ),
    children: [
      {
        index: true,
        element: <AddUsersAuthPage />,
      },
    ],
  },
  {
    path: paths.rocketAuth.editUser,
    element: (
      <DashboardLayout>
        <Suspense>
          <Outlet />
        </Suspense>
      </DashboardLayout>
    ),
    children: [
      {
        index: true,
        element: <EditUsersAuthPage />,
      },
    ],
  },
];
