export const paths = {
  profile: {
    index: '/profile',
    settings: '/profile/settings',
  },
  auth: {
    confirmRegister: '/auth/confirm-register',
    forgotPassword: '/auth/forgot-password',
    login: '/auth/login',
    register: '/auth/register',
    resetPassword: '/auth/reset-password',
  },
  overview: {
    index: '/',
  },
  database: {
    index: '/databases',
    new: '/databases/create',
    edit: '/databases/:id',
  },
  table: {
    index: '/tables',
    create: '/tables/create',
    edit: '/tables/:id/edit',
    details: '/tables/:id',
    newAttribute: '/tables/:id/new-attribute',
    editAttribute: '/tables/:id/:attId',
  },
  relationships: {
    index: '/relationships',
    tableRelationships: '/relationships/:id',
    editRelationship: '/relationships/:id/:attId',
  },
  rocketAuth: {
    index: '/rocket-auth',
    newUser: '/rocket-auth/new-user',
    editUser: '/rocket-auth/:id',
  },
  data: {
    index: '/data',
    table: '/data/:tableId',
    addTable: 'data/:tableId/add-data',
    editTable: 'data/:tableId/:dataId',
  },
  syntheticData: {
    index: '/synthetic-data',
    tableDetails: '/synthetic-data/:tableId',
    setSyntheticAttribute: '/synthetic-data/:tableId/:attributeId',
    generate: '/synthetic-data/:tableId/generate',
  },
  apis: {
    index: '/apis',
    editTable: '/apis/:apiId',
  },
  queries: {
    index: '/queries',
    table: '/queries/:tableId',
    addQueries: '/queries/:tableId/add-queries',
    editQueries: '/queries/:tableId/:queryId',
  },
  webhook: {
    index: '/webhook',
    editTable: '/webhook/:apiId',
  },
  docs: 'https://help.rocketapi.net/',
  termsAndCondition: 'https://rocketapi.net/terms-and-conditions',
  acknowledgements: 'https://rocketapi.net/acknowledgement',
  discord: 'https://discord.gg/a2q38gGSyH',
  discussions: 'https://github.com/rocket-org-imdeepmind/Rocket/discussions',
};
