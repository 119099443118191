import { useCallback, useEffect, useMemo, useReducer } from 'react';

import PropTypes from 'rocket/Libraries/prop-types';

import { Auth } from '@aws-amplify/auth';

import { generateFromString } from 'generate-avatar';
import { amplifyConfig } from 'src/config';

import { AuthContext, initialState } from './auth-context';

Auth.configure({
  userPoolId: amplifyConfig.aws_user_pools_id,
  userPoolWebClientId: amplifyConfig.aws_user_pools_web_client_id,
  region: amplifyConfig.aws_cognito_region,
});

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  },
  SIGN_IN: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
  SIGN_OUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null,
  }),
};

const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

export function AuthProvider(props) {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);

  const initialize = useCallback(async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();

      dispatch({
        type: 'INITIALIZE',
        payload: {
          isAuthenticated: true,
          user: {
            id: user.attributes.sub,
            avatar: generateFromString(user.attributes.email),
            email: user.attributes.email,
            name: user.attributes.name,
            plan: 'Bronze',
          },
        },
      });
    } catch (error) {
      dispatch({
        type: 'INITIALIZE',
        payload: {
          isAuthenticated: false,
          user: null,
        },
      });
    }
  }, [dispatch]);

  useEffect(
    () => {
      initialize();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const signOut = useCallback(async () => {
    await Auth.signOut();
    dispatch({
      type: 'SIGN_OUT',
    });
  }, [dispatch]);

  const signIn = useCallback(
    async (email, password) => {
      const user = await Auth.signIn(email, password);

      dispatch({
        type: 'SIGN_IN',
        payload: {
          user: {
            id: user.attributes.sub,
            avatar: generateFromString(user.attributes.email),
            email: user.attributes.email,
            name: user.attributes.name,
            plan: 'Bronze',
          },
        },
      });
    },
    [dispatch]
  );

  const signUp = useCallback(async (name, email, password) => {
    await Auth.signUp({
      username: email,
      password,
      attributes: { name, email },
    });
  }, []);

  const confirmSignUp = useCallback(async (username, code) => {
    await Auth.confirmSignUp(username, code);
  }, []);

  const resendSignUp = useCallback(async (username) => {
    await Auth.resendSignUp(username);
  }, []);

  const forgotPassword = useCallback(async (username) => {
    await Auth.forgotPassword(username);
  }, []);

  const forgotPasswordSubmit = useCallback(async (username, code, newPassword) => {
    await Auth.forgotPasswordSubmit(username, code, newPassword);
  }, []);

  const updateName = useCallback(
    async (name) => {
      await Auth.updateUserAttributes(await Auth.currentAuthenticatedUser(), {
        name,
      });

      const user = await Auth.currentAuthenticatedUser();

      dispatch({
        type: 'INITIALIZE',
        payload: {
          isAuthenticated: true,
          user: {
            id: user.attributes.sub,
            avatar: generateFromString(user.attributes.email),
            email: user.attributes.email,
            name: user.attributes.name,
            plan: 'Bronze',
          },
        },
      });
    },
    [dispatch]
  );

  const updatePassword = useCallback(async (oldPassword, newPassword) => {
    const user = await Auth.currentAuthenticatedUser();
    await Auth.changePassword(user, oldPassword, newPassword);
  }, []);

  const memoState = useMemo(
    () => ({
      ...state,
      signIn,
      signUp,
      confirmSignUp,
      resendSignUp,
      forgotPassword,
      forgotPasswordSubmit,
      signOut,
      updateName,
      updatePassword,
    }),
    [
      state,
      signIn,
      signUp,
      confirmSignUp,
      resendSignUp,
      forgotPassword,
      forgotPasswordSubmit,
      signOut,
      updateName,
      updatePassword,
    ]
  );

  return <AuthContext.Provider value={memoState}>{children}</AuthContext.Provider>;
}

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
