import { lazy } from 'react';
import { Outlet } from 'react-router-dom';

import AuthLayout from 'src/components/layouts/auth';
import GuestGuard from 'src/guards/guest-guard';

import { paths } from 'src/paths';

const ConfirmRegisterPage = lazy(() => import('src/pages/auth/confirm-register'));
const ForgotPasswordPage = lazy(() => import('src/pages/auth/forgot-password'));
const LoginPage = lazy(() => import('src/pages/auth/login'));
const RegisterPage = lazy(() => import('src/pages/auth/register'));
const ResetPasswordPage = lazy(() => import('src/pages/auth/reset-password'));

export const authRoutes = [
  {
    path: paths.auth.confirmRegister,
    element: (
      <GuestGuard>
        <AuthLayout>
          <Outlet />
        </AuthLayout>
      </GuestGuard>
    ),
    children: [
      {
        index: true,
        element: <ConfirmRegisterPage />,
      },
    ],
  },
  {
    path: paths.auth.forgotPassword,
    element: (
      <GuestGuard>
        <AuthLayout>
          <Outlet />
        </AuthLayout>
      </GuestGuard>
    ),
    children: [
      {
        index: true,
        element: <ForgotPasswordPage />,
      },
    ],
  },
  {
    path: paths.auth.login,
    element: (
      <GuestGuard>
        <AuthLayout>
          <Outlet />
        </AuthLayout>
      </GuestGuard>
    ),
    children: [
      {
        index: true,
        element: <LoginPage />,
      },
    ],
  },
  {
    path: paths.auth.register,
    element: (
      <GuestGuard>
        <AuthLayout>
          <Outlet />
        </AuthLayout>
      </GuestGuard>
    ),
    children: [
      {
        index: true,
        element: <RegisterPage />,
      },
    ],
  },
  {
    path: paths.auth.resetPassword,
    element: (
      <GuestGuard>
        <AuthLayout>
          <Outlet />
        </AuthLayout>
      </GuestGuard>
    ),
    children: [
      {
        index: true,
        element: <ResetPasswordPage />,
      },
    ],
  },
];
