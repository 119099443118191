import { forwardRef } from 'react';
import { Link } from 'react-router-dom';

const RouterLink = function RouterLink(props, ref) {
  // eslint-disable-next-line react/prop-types
  const { href, ...other } = props;

  return (
    <Link
      ref={ref}
      to={href}
      {...other}
    />
  );
};

export default forwardRef(RouterLink);
