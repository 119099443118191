import { useMemo } from 'react';

import SvgIcon from 'src/components/bits/SvgIcon';

import { paths } from 'src/paths';
import {
  APIsIcon,
  AuthIcon,
  ChartIcon,
  DataIcon,
  DatabaseIcon,
  FakerIcon,
  OtherIcon,
  RelationshipIcon,
  QueriesIcon,
  TableIcon,
  WebhookIcon,
} from 'src/icons';

export const useSections = () =>
  useMemo(
    () => [
      {
        items: [
          {
            title: 'Overview',
            path: paths.overview.index,
            icon: (
              <SvgIcon fontSize="small">
                <ChartIcon />
              </SvgIcon>
            ),
          },
          'divide',
          {
            title: 'Database',
            path: paths.database.index,
            icon: (
              <SvgIcon fontSize="small">
                <DatabaseIcon />
              </SvgIcon>
            ),
          },
          {
            title: 'Tables',
            path: paths.table.index,
            icon: (
              <SvgIcon fontSize="small">
                <TableIcon />
              </SvgIcon>
            ),
          },
          {
            title: 'Relationships',
            path: paths.relationships.index,
            icon: (
              <SvgIcon fontSize="small">
                <RelationshipIcon />
              </SvgIcon>
            ),
          },
          {
            title: 'Queries',
            path: paths.queries.index,
            icon: (
              <SvgIcon fontSize="small">
                <QueriesIcon />
              </SvgIcon>
            ),
          },
          'divide',
          {
            title: 'Authentication',
            path: paths.rocketAuth.index,
            icon: (
              <SvgIcon fontSize="small">
                <AuthIcon />
              </SvgIcon>
            ),
          },
          'divide',
          {
            title: 'Data',
            path: paths.data.index,
            icon: (
              <SvgIcon fontSize="small">
                <DataIcon />
              </SvgIcon>
            ),
          },
          {
            title: 'Synthetic Data',
            path: paths.syntheticData.index,
            icon: (
              <SvgIcon fontSize="small">
                <FakerIcon />
              </SvgIcon>
            ),
          },
          'divide',
          {
            title: 'APIs',
            path: paths.apis.index,
            icon: (
              <SvgIcon fontSize="small">
                <APIsIcon />
              </SvgIcon>
            ),
          },
          {
            title: 'Webhooks',
            path: paths.webhook.index,
            icon: (
              <SvgIcon fontSize="small">
                <WebhookIcon />
              </SvgIcon>
            ),
          },
          'divide',
          {
            title: 'Other',
            icon: (
              <SvgIcon fontSize="small">
                <OtherIcon />
              </SvgIcon>
            ),
            items: [
              {
                title: 'Join Discord Server',
                path: paths.discord,
                external: true,
              },

              {
                title: 'Github Discussions',
                path: paths.discussions,
                external: true,
              },
              {
                title: 'Acknowledgements',
                path: paths.acknowledgements,
                external: true,
              },
              {
                title: 'Terms and Conditions',
                path: paths.termsAndCondition,
                external: true,
              },
            ],
          },
        ],
      },
    ],
    []
  );
