export const isSamePath = (pathname, path) => {
  if (!path || !pathname) return false;
  if (pathname === path) return true;
  if (path === '/') return false;

  // eslint-disable-next-line no-restricted-syntax
  for (const pa of pathname.split('/')) {
    if (pa === path.substring(1)) return true;
  }

  return false;
};
