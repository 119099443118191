import { Helmet } from 'react-helmet-async';
import PropTypes from 'rocket/Libraries/prop-types';

function Seo(props) {
  const { title } = props;

  const fullTitle = title
    ? `${title} | Rocket: The Perfect Low-Code Backend Builder`
    : 'Rocket: The Perfect Low-Code Backend Builder';

  return (
    <Helmet>
      <title>{fullTitle}</title>
    </Helmet>
  );
}

Seo.defaultProps = {
  title: '',
};

Seo.propTypes = {
  title: PropTypes.string,
};

export default Seo;
