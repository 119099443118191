import PropTypes from 'rocket/Libraries/prop-types';

import Chip from 'src/components/bits/Chip';
import Stack from 'src/components/bits/Stack';
import SvgIcon from 'src/components/bits/SvgIcon';
import Typography from 'src/components/bits/Typography';

import { CloudSun, NightIcon, SunIcon } from 'src/icons';

const options = [
  {
    label: 'Light',
    value: 'light',
    icon: (
      <SvgIcon fontSize="small">
        <SunIcon />
      </SvgIcon>
    ),
  },
  {
    label: 'Dark',
    value: 'dark',
    icon: (
      <SvgIcon fontSize="small">
        <CloudSun />
      </SvgIcon>
    ),
  },
  {
    label: 'Hyper Dark',
    value: 'hyper-dark',
    icon: (
      <SvgIcon fontSize="small">
        <NightIcon />
      </SvgIcon>
    ),
  },
];

function OptionsColorScheme(props) {
  const { onChange, value } = props;

  return (
    <Stack spacing={1}>
      <Typography
        color="text.secondary"
        variant="overline"
      >
        Color Scheme
      </Typography>
      <Stack
        alignItems="center"
        direction="row"
        flexWrap="wrap"
        gap={2}
      >
        {options.map((option) => (
          <Chip
            icon={option.icon}
            key={option.value}
            label={option.label}
            onClick={() => onChange?.(option.value)}
            sx={{
              borderColor: 'transparent',
              borderRadius: 1.5,
              borderStyle: 'solid',
              borderWidth: 2,
              ...(option.value === value && {
                borderColor: 'primary.main',
              }),
            }}
          />
        ))}
      </Stack>
    </Stack>
  );
}

OptionsColorScheme.defaultProps = {
  value: 'light',
  onChange: null,
};

OptionsColorScheme.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.oneOf(['light', 'dark']),
};

export default OptionsColorScheme;
