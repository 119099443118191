import { alpha } from '@mui/system/colorManipulator';

import PropTypes from 'rocket/Libraries/prop-types';

import Box from 'src/components/bits/Box';
import IconButton from 'src/components/bits/Button/IconButton';
import Stack from 'src/components/bits/Stack';
import SvgIcon from 'src/components/bits/SvgIcon';

import { useRouter } from 'rocket/Hooks/use-router';
import useMediaQuery from 'rocket/Hooks/use-media-query';

import { ArrowLeftIcon, MenuIcon } from 'src/icons';

import AccountButton from '../account-button';
import NavBreadCrumbs from './nav-breadcrumbs';

const TOP_NAV_HEIGHT = 64;
const SIDE_NAV_WIDTH = 280;

function TopNav(props) {
  const { onMobileNavOpen, showBreadcrumbs, ...other } = props;
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'));
  const { back } = useRouter();

  return (
    <Box
      component="header"
      sx={{
        backdropFilter: 'blur(6px)',
        backgroundColor: (theme) => alpha(theme.palette.background.default, 0.8),
        position: 'sticky',
        left: {
          lg: `${SIDE_NAV_WIDTH}px`,
        },
        top: 0,
        width: {
          lg: `calc(100% - ${SIDE_NAV_WIDTH}px)`,
        },
        zIndex: (theme) => theme.zIndex.appBar,
      }}
      {...other}
    >
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        spacing={2}
        sx={{
          minHeight: TOP_NAV_HEIGHT,
          px: 2,
        }}
      >
        <Stack
          alignItems="center"
          direction="row"
          spacing={2}
        >
          {!lgUp && (
            <IconButton onClick={onMobileNavOpen}>
              <SvgIcon>
                <MenuIcon />
              </SvgIcon>
            </IconButton>
          )}
          <IconButton onClick={back}>
            <SvgIcon>
              <ArrowLeftIcon />
            </SvgIcon>
          </IconButton>

          {showBreadcrumbs && <NavBreadCrumbs />}
        </Stack>
        <Stack
          alignItems="center"
          direction="row"
          spacing={2}
        >
          <AccountButton />
        </Stack>
      </Stack>
    </Box>
  );
}

TopNav.defaultProps = {
  onMobileNavOpen: null,
  showBreadcrumbs: true,
};

TopNav.propTypes = {
  onMobileNavOpen: PropTypes.func,
  showBreadcrumbs: PropTypes.bool,
};

export default TopNav;
