import axios from 'axios';
import axiosRetry from 'axios-retry';

import { apiUri } from 'src/config';
import { logger } from 'src/utilsV2/logger';
import { getAccessToken } from 'src/utilsV2/auth';

axiosRetry(axios, {
  retries: 3,
  retryDelay: (retryCount) => retryCount * 500,
  retryCondition: (error) => error?.response?.status === 401,
});

export async function httpGet(url) {
  logger(`httpGet: Calling ${url}`);

  try {
    const accessToken = await getAccessToken();

    const resp = await axios.get(`${apiUri}${url}`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });

    const { data, status } = resp;

    if (status === 200) {
      return data.data;
    }

    throw Error(data?.message ?? 'Failed to fetch data');
  } catch (error) {
    throw Error(error?.response?.data?.message ?? error.message ?? 'Failed to fetch data');
  }
}

export async function httpPost(url, body) {
  logger(`httpPost: Calling ${url}`);

  try {
    const accessToken = await getAccessToken();

    const resp = await axios.post(`${apiUri}${url}`, body, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });

    const { data, status } = resp;

    if (status === 201) {
      return data.data;
    }

    throw Error(data?.message ?? 'Failed to create data');
  } catch (error) {
    throw Error(error?.response?.data?.message ?? error.message ?? 'Failed to fetch data');
  }
}

export async function httpPut(url, body) {
  logger(`httpPut: Calling ${url}`);

  try {
    const accessToken = await getAccessToken();

    const resp = await axios.put(`${apiUri}${url}`, body, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });

    const { data, status } = resp;

    if (status === 201) {
      return data.data;
    }

    throw Error(data?.message ?? 'Failed to update data');
  } catch (error) {
    throw Error(error?.response?.data?.message ?? error.message ?? 'Failed to fetch data');
  }
}

export async function httpDelete(url) {
  logger(`httpDelete: Calling ${url}`);

  try {
    const accessToken = await getAccessToken();

    const resp = await axios.delete(`${apiUri}${url}`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });

    const { data, status } = resp;

    if (status === 204) {
      return null;
    }

    throw Error(data?.message ?? 'Failed to delete data');
  } catch (error) {
    throw Error(error?.response?.data?.message ?? error.message ?? 'Failed to fetch data');
  }
}
