import Box from '@mui/material/Box';

function BreadcrumbsSeparator() {
  return (
    <Box
      sx={{
        backgroundColor: 'neutral.500',
        borderRadius: '50%',
        height: 4,
        width: 4,
      }}
    />
  );
}

export default BreadcrumbsSeparator;
