import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import { Layout as DashboardLayout } from 'src/components/layouts/dashboard';
import { paths } from 'src/paths';

const ProfilePage = lazy(() => import('src/pages/profile/profile'));
const SettingsPage = lazy(() => import('src/pages/profile/settings'));

export const profileRoutes = [
  {
    path: paths.profile.index,
    element: (
      <DashboardLayout selectedDatabaseNeeded={false}>
        <Suspense>
          <Outlet />
        </Suspense>
      </DashboardLayout>
    ),
    children: [
      {
        index: true,
        element: <ProfilePage />,
      },
    ],
  },
  {
    path: paths.profile.settings,
    element: (
      <DashboardLayout selectedDatabaseNeeded={false}>
        <Suspense>
          <Outlet />
        </Suspense>
      </DashboardLayout>
    ),
    children: [
      {
        index: true,
        element: <SettingsPage />,
      },
    ],
  },
];
