import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import { Layout as DashboardLayout } from 'src/components/layouts/dashboard';
import { paths } from 'src/paths';

const SyntheticDataPage = lazy(() => import('src/pages/synthetic-data'));
const SyntheticDataTableDetailsPage = lazy(() => import('src/pages/synthetic-data/table-details'));
const SetSyntheticDataPage = lazy(() => import('src/pages/synthetic-data/set-synthetic-data'));
const GenerateSyntheticDataPage = lazy(() => import('src/pages/synthetic-data/generate'));

export const syntheticDataRoutes = [
  {
    path: paths.syntheticData.index,
    element: (
      <DashboardLayout>
        <Suspense>
          <Outlet />
        </Suspense>
      </DashboardLayout>
    ),
    children: [
      {
        index: true,
        element: <SyntheticDataPage />,
      },
    ],
  },
  {
    path: paths.syntheticData.tableDetails,
    element: (
      <DashboardLayout>
        <Suspense>
          <Outlet />
        </Suspense>
      </DashboardLayout>
    ),
    children: [
      {
        index: true,
        element: <SyntheticDataTableDetailsPage />,
      },
    ],
  },
  {
    path: paths.syntheticData.generate,
    element: (
      <DashboardLayout>
        <Suspense>
          <Outlet />
        </Suspense>
      </DashboardLayout>
    ),
    children: [
      {
        index: true,
        element: <GenerateSyntheticDataPage />,
      },
    ],
  },
  {
    path: paths.syntheticData.setSyntheticAttribute,
    element: (
      <DashboardLayout>
        <Suspense>
          <Outlet />
        </Suspense>
      </DashboardLayout>
    ),
    children: [
      {
        index: true,
        element: <SetSyntheticDataPage />,
      },
    ],
  },
];
