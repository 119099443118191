import PropTypes from 'rocket/Libraries/prop-types';

import Box from 'src/components/bits/Box';
import Button from 'src/components/bits/Button';
import Container from 'src/components/bits/Container';
import Seo from 'src/components/bits/Seo';
import Typography from 'src/components/bits/Typography';

import useMediaQuery from 'rocket/Hooks/use-media-query';

function Error(props) {
  const { title } = props;
  const mdUp = useMediaQuery((theme) => theme.breakpoints.down('md'));

  let text;
  if (typeof title === 'object') {
    text = `${title?.message ?? JSON.stringify(title)}`;
  } else {
    text = `${title}`;
  }

  return (
    <>
      <Seo title={text} />
      <Box
        component="main"
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexGrow: 1,
          py: '80px',
        }}
      >
        <Container maxWidth="lg">
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              mb: 6,
            }}
          >
            <Box
              alt="Internal server error"
              component="img"
              src="/assets/503.png"
              sx={{
                height: 'auto',
                maxWidth: '100%',
                width: 400,
              }}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              mb: 6,
            }}
          >
            <Typography
              align="center"
              variant={mdUp ? 'h1' : 'h4'}
            >
              {text}
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Button onClick={() => window.location.reload()}>Refresh</Button>
          </Box>
        </Container>
      </Box>
    </>
  );
}

Error.propTypes = {
  title: PropTypes.string.isRequired,
};

export default Error;
