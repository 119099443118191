import useSWR, { useSWRConfig } from 'swr';

import { httpDelete, httpGet, httpPost, httpPut } from 'src/utilsV2/api';

const ROOT = 'api/v1/database';

export function useDatabase() {
  const url = ROOT;

  const { data, error, isLoading, mutate } = useSWR(url, () => httpGet(url), {
    revalidateIfStale: false,
    refreshInterval: 1000 * 60 * 3,
  });
  const { mutate: revalidateGlobalCache, cache } = useSWRConfig();

  const createDatabase = async (body) => {
    await httpPost(url, body);
    mutate();
  };

  const deleteDatabase = async (id) => {
    await httpDelete(`${url}/${id}`);
    mutate();

    revalidateGlobalCache(`api/v1/user/subscription/check/database`);

    // TODO: Need to check the caching issue, just a hack for now
    cache.delete(`api/v1/user/subscription/check/database`);
  };

  return {
    databases: data,
    error,
    isLoading,
    createDatabase,
    deleteDatabase,
  };
}

export function useDatabaseById(databaseId) {
  const url = `${ROOT}/${databaseId}`;

  const { data, error, isLoading, mutate } = useSWR(url, () => httpGet(url), {
    revalidateIfStale: false,
    refreshInterval: 1000 * 60 * 3,
  });
  const { mutate: revalidateGlobalCache } = useSWRConfig();

  const updateDatabase = async (body) => {
    await httpPut(url, body);
    mutate();
    revalidateGlobalCache(ROOT);
  };

  return {
    database: data,
    error,
    isLoading,
    updateDatabase,
  };
}
